import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import { alpha, styled } from '@mui/material/styles';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';

export type IInputFilled = BaseTextFieldProps &
  TextFieldProps & { label: string } & { sx?: React.CSSProperties };

export default function InputFilled({
  label = 'title',
  sx = {},
  onChange,
  ...other
}: Partial<IInputFilled>) {
  return (
    <TextFieldFilled
      label={label.toUpperCase()}
      onChange={onChange}
      variant="filled"
      sx={{ ...sx }}
      {...other}
    />
  );
}

export const TextFieldFilled = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{
      ...({ disableUnderline: true } as Partial<OutlinedInputProps>),
      ...props.InputProps,
    }}
    sx={{ ...props.sx }}
    {...props}
  />
))(({ theme }) => {
  return {
    '& .MuiFilledInput-root': {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        border: '1px solid #000',
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiFilledInput-input': {
        fontFamily: theme.typography.fontFamily,
      },
    },
  };
});
