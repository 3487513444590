import axios from 'axios';

import { getDataFromLocalStorage } from '../helpers/methods';
import { REACT_APP_BASE_URL } from '../helpers/config';

interface IGetAsyncData {
  url: string;
  req?: any;
  type?: 'post' | 'get' | 'delete' | 'download';
  withToken?: boolean;
}

export async function getAsyncData({
  url,
  req = {},
  type = 'post',
  withToken = true,
}: IGetAsyncData): Promise<any> {
  const reqUrl = REACT_APP_BASE_URL + url;
  const token = withToken && getDataFromLocalStorage('token');

  let res;
  switch (type) {
    case 'get': {
      res = await axios[type](reqUrl, {
        params: req,
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status: number) => {
          return true;
        },
      });
      break;
    }
    case 'post': {
      res = await axios[type](reqUrl, req, {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status: number) => {
          return true;
        },
      });
      break;
    }
    case 'delete': {
      res = await axios[type](reqUrl, {
        headers: { Authorization: `Bearer ${token}` },
        data: req,
        validateStatus: (status: number) => {
          return true;
        },
      });
      break;
    }
    case 'download': {
      res = await axios['get'](reqUrl, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
        validateStatus: (status: number) => {
          return true;
        },
      });
      break;
    }
  }

  return res.data;
}

export function catchError(error: any, showMessage: any) {
  if (error && error.code === 'ERR_NETWORK') {
    showMessage('error', `Помилка серверу!`);
  }
}
