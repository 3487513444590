import { AlertType } from '../context/snackbar-context';
import { getAsyncData } from './axios.service';
import { ISetRRO } from '../module/RRO/rro.interface';

export const getLocalRROFromServer = async (
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  let res;

  try {
    res = await getAsyncData({
      url: `/cso/rro/local`,
      type: 'get',
      req: {},
    });

    if (res.error.errorCode === 200) {
      return res.data;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
    }
  } catch (e) {
    console.log(e);
  }
};

export const setLocalRRO = async (
  id: number | string,
  newRow: ISetRRO,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  let res;
  newRow = { ...newRow, terminalId: Number(newRow.terminalId) };
  try {
    res = await getAsyncData({
      url: `/cso/rro/${id}/edit`,
      type: 'post',
      req: newRow,
    });

    if ([200, 201].indexOf(res.error.errorCode) !== -1) {
      return res.data;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
    }
  } catch (e) {
    console.log(e);
  }
};

export const geSyncRROFromCSO = async (
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  let res;

  try {
    res = await getAsyncData({
      url: `/cso/rro/sync`,
      type: 'get',
      req: {},
    });

    if (res.error.errorCode === 200) {
      return res.data;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
    }
  } catch (e) {
    console.log(e);
  }
};
