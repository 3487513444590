import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Columns from './components/Columns/Columns';
import Rows, { IRow } from './components/Rows/Rows';
import { IOrderBy, ICustomTable } from './interface';
import Loading from '../Loading/Loading';
import CustomTableContext from './helpers/table.context';
import CustomPagination from './components/ui/CustomPagination';
import { Title } from './components';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ActionButton from './components/ui/ActionButton/ActionButton';

const WrapperTableTitle = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: '1rem 0',
}));

const WrapperTable = styled(Table)((props) => ({
  '.colorEvenHead > .MuiTableRow-root > .MuiTableCell-root': {
    backgroundColor: `${props.theme.palette.primary.main}bb `,
  },
  '.colorEvenRow > .MuiTableRow-root:nth-of-type(even)': {
    backgroundColor: `${props.theme.palette.primary.main}55 `,
  },
}));

export default function CustomTable({
  title,
  columns,
  rows,
  isLoading = false,
  isSimple = false,
  count,
  rowsPerPageOptions = [25, 50, 100],
  rowsPerPageDefault = rowsPerPageOptions[0],
  labelEmptyRows = 'No rows',
  fnRefreshData,
  onDoubleClick,
  onClick,
  sx,
  actionButton,
  ...rest
}: ICustomTable) {
  /* Сортировка таблицы */
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    sortField: '',
    sortingType: 'asc',
  });

  const handleChangeOrderBy = (orderBy: IOrderBy) => {
    setOrderBy(orderBy);
  };

  const [pageNum, setPageNum] = useState<number>(1);
  const handleChangePageNum = (pageNum: number) => {
    setPageNum(pageNum);
  };

  const [selectedRow, setRow] = useState<IRow>();

  useEffect(() => {
    fnRefreshData && fnRefreshData({ pageNum, orderBy });
  }, [pageNum, orderBy]);

  /* Подготовка полей для таблицы */
  const prepareRows = useMemo(() => {
    return rows;
  }, [rows]);

  const handleRowDoubleClick = (row: IRow) => {
    setRow(row);
    onDoubleClick && onDoubleClick(row);
  };

  const handleRowClick = (row: IRow) => {
    setRow(row);
    onClick && onClick(row);
  };

  const tableAction = useMemo(() => {
    return (
      title && (
        <WrapperTableTitle>
          <Title title={title} />
          {actionButton && (
            <ActionButton action={actionButton} row={selectedRow} />
          )}
        </WrapperTableTitle>
      )
    );
  }, [title, actionButton, selectedRow]);

  //	<FilterContext.Provider value={ { filter } }>
  return (
    <CustomTableContext.Provider value={{ param: { ...rest } }}>
      {tableAction}
      <TableContainer sx={{ minHeight: '90px', ...sx }}>
        <WrapperTable size={'small'} stickyHeader>
          <TableHead className={'colorEvenHead'}>
            <TableRow hover>
              <Columns
                columns={columns}
                orderBy={orderBy}
                onChangeOrderBy={handleChangeOrderBy}
              />
            </TableRow>
          </TableHead>
          <TableBody className={'colorEvenRow'}>
            {isLoading ? (
              <Loading />
            ) : (
              <Rows
                rows={prepareRows}
                columns={columns}
                labelEmptyRows={labelEmptyRows}
                onDoubleClick={handleRowDoubleClick}
                onClick={handleRowClick}
                selectedRow={selectedRow}
              />
            )}
          </TableBody>
        </WrapperTable>
      </TableContainer>
      {!isSimple && (
        <CustomPagination
          count={count}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPageDefault={rowsPerPageDefault}
          onChangePage={handleChangePageNum}
        />
      )}
    </CustomTableContext.Provider>
  );
}
