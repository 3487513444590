import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";
import { ISingleRow } from "../../../interface/interface.custom";
import InputPhone from "../../ui/InputPhone";

const IconButtonCustom = styled( IconButton )( ( props ) => ( {
	padding: 0,
	"&:hover": {
		color: props.property === 'initial' ? 'text.secondary' : props.property === 'apply' ? '#4a9a4a' : '#c75b5b'
	}
} ) );

const Container = styled( Grid )( ( props ) => ( {

	position: 'relative',
	[ props.theme.breakpoints.down( 'md' ) ]: {
		width: '100px'
	},
	'& .MuiInputBase-root:before, & .MuiInputBase-root:after': { display: 'none' },
	"& input": {
		padding: "0rem 3rem .1rem .5rem",
		width: '100%',
		border: '1px solid #ccc',
		borderRadius: '5px'
	}
} ) );


const ContainerField = styled( Grid )( () => ( {
	display: 'flex',
	justifyContent: 'space-between'
} ) );

const ContainerBtn = styled( Grid )( () => ( {
	position: 'absolute',
	right: 0,
	top: 0
} ) );

interface ICellTextEditor {
	row: any;
	field: string;

	onCommitChanges? ( { row }: ISingleRow ): Promise<boolean>;
}

export default function CellPhoneEditor ( { field, row, onCommitChanges }: ICellTextEditor ) {
	const [ isShowEdit, setShowEdit ] = useState( false );
	const [ val, setValue ] = useState<string>( '' );
	const [ valCommit, setValueCommit ] = useState<string>( '' );

	useEffect( () => {
		setValue( row[ field ] );
		setValueCommit( row[ field ] );
	}, [ row, field ] )

	const handleOpenEditField = () => {
		setShowEdit( true );
	};

	const handleCancelEditField = () => {
		setValueCommit( val );
		setShowEdit( false );
	};

	const handleSaveEditField = () => {
		setValue(valCommit)

		const newRow = row && { ...row, [ field ]: valCommit }
		if ( onCommitChanges )
			onCommitChanges( { row: newRow } )
				.then( res => {
					if ( res ) {
						setShowEdit( false );
					}
				} )
	};

	const handleChangeInput = ( e: React.ChangeEvent<HTMLInputElement> ) => {
		setValueCommit( e.target.value )
	};

	return <>
		<Grid sx={ { boxSizing: 'border-box' } }>
			{
				isShowEdit
					?
					<Container>
						<InputPhone value={ valCommit } size={ 'small' } onChange={ handleChangeInput }/>
						<ContainerBtn>
							<IconButtonCustom property={ 'apply' } onClick={ handleSaveEditField }><SaveAsIcon/></IconButtonCustom>
							<IconButtonCustom property={ 'cancel' } onClick={ handleCancelEditField }><CancelIcon/></IconButtonCustom>
						</ContainerBtn>
					</Container>
					:
					<ContainerField>
						<>
							<Typography sx={ { flex: "1", fontSize: "inherit" } }>{ val }</Typography>
							<IconButtonCustom property={ 'initial' } onClick={ handleOpenEditField }> <EditIcon/>
							</IconButtonCustom>
						</>
					</ContainerField>
			}

		</Grid>
	</>
}
