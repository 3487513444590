import { Grid, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { ISingleRow } from "../../../interface/interface.custom";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { IRow } from "../../Rows/Rows";

const SelectCustom = styled( Select )( () => ( {
	"& .MuiSelect-select": {
		padding: "0 5rem 0 0"
	},
	"& .MuiOutlinedInput-notchedOutline": {
		border: 'none'
	}
} ) );

const IconButtonCustom = styled( IconButton )( ( props ) => ( {
	padding: 0,
	"&:hover": {
		color: props.property === 'initial' ? 'text.secondary' : props.property === 'apply' ? '#4a9a4a' : '#c75b5b'
	}
} ) );

const Container = styled( Grid )( ( props ) => ( {
	position: 'relative',
	paddingRight: '3rem',
	display: 'flex',
	justifyContent: 'flex-end',
	[ props.theme.breakpoints.down( 'md' ) ]: {
		width: '100px'
	}
} ) );

const ContainerBtn = styled( Grid )( () => ( {
	position: 'absolute',
	right: 0,
	top: 0
} ) );

const ContainerField = styled( Grid )( () => ( {
	display: 'flex',
	justifyContent: 'space-between'
} ) );

interface IArray {
	id: number | string;
	option: string;
}

interface ICellCombobox {
	value: number | string;
	array: IArray[];
	row: IRow;

	onCommitChanges? ( { row }: ISingleRow ): Promise<boolean>;
}

export default function CellCombobox ( { value, array, onCommitChanges }: ICellCombobox ) {
	const [ isShowEdit, setShowEdit ] = useState( false );
	const [ val, setValue ] = useState<typeof value>( value );

	useEffect( () => {
		setValue( value );
	}, [ value ] )

	const handleChange = ( e: SelectChangeEvent<any> ) => {
		setValue( e.target.value );
	};


	const handleSaveEditField = () => {
		console.log( 'handleSave' )
	};

	const handleOpenEditField = () => {
		setShowEdit( true );
	};

	const handleCancelEditField = () => {
		setShowEdit( false );
	}

	return <>
		{
			isShowEdit
				?
				<Container>
					<SelectCustom
						value={ val }
						onChange={ handleChange }
					>
						{ array.map( a => ( <MenuItem key={ a.id } value={ a.id }>{ a.option }</MenuItem> ) ) }
					</SelectCustom>
					<ContainerBtn>
						<IconButtonCustom property={ 'apply' } onClick={ handleSaveEditField }><SaveAsIcon/></IconButtonCustom>
						<IconButtonCustom property={ 'cancel' } onClick={ handleCancelEditField }><CancelIcon/></IconButtonCustom>
					</ContainerBtn>
				</Container>
				: <ContainerField>
					<>
						<Typography sx={ { flex: "1", fontSize: "inherit" } }>{ val }</Typography>
						<IconButtonCustom property={ 'initial' } onClick={ handleOpenEditField }> <EditIcon/>
						</IconButtonCustom>
					</>
				</ContainerField>
		}

	</>
}