import { AlertType } from '../context/snackbar-context';
import { getAsyncData } from './axios.service';
import { Filter } from '../module/CSO/cso.interface';

export const getLastConnectDate = async (
  filter: Filter,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  let res;
  let req = {
    numberHoursBeforeRROLock: filter.numberHoursBeforeRROLock,
  };

  try {
    res = await getAsyncData({
      url: `/cso/rro`,
      type: 'get',
      req,
    });

    if (!res?.error) {
      showMessage('error', JSON.stringify(res));
    }

    if (res.error.errorCode === 200) {
      return res.data;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
    }
  } catch (e) {
    console.log(e);
  }
};
