import { makeStyles } from '@mui/styles';
import { alpha, styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { TextFieldProps } from '@mui/material/TextField';

export const useCustomStyles = makeStyles((theme: any) => {
  const fontFamilyDefault = theme?.font?.fontFamilyDefault || 'inherit';
  const fontFamilyLabelBold = theme?.font?.fontFamilyLabelBold || 'inherit';

  return {
    link: {
      textDecoration: 'inherit',
      color: '#ccc',
      display: 'flex',
      width: '100%',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    activeLink: {
      textDecoration: 'inherit',
      color: theme.palette.primary.contrastText,
      display: 'flex',
      width: '100%',
      backgroundColor: '#be7f3b',
      '& hover': {
        color: theme.palette.primary.dark,
      },
    },

    titleDefault: {
      fontFamily: fontFamilyDefault,
      color: '#000',
      fontWeight: '600',
      fontSize: '16px',
      lineHeight: '21px',
      letterSpacing: '0.01em',
    },

    titleBold: {
      fontFamily: fontFamilyLabelBold,
      color: '#000',
      fontWeight: '700',
      fontSize: '22px',
      lineHeight: '27px',
    },

    fontDefault: {
      fontFamily: fontFamilyDefault,
    },
  };
});

export const TextFieldFilled = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    sx={{ ...props.sx }}
    {...props}
  />
))(({ theme }: { theme: any }) => {
  return {
    '& .MuiFilledInput-root': {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        border: '1px solid #000',
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
      '& .MuiFilledInput-input': {
        fontFamily: theme.typography.fontFamily,
      },
    },
  };
});
