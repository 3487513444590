import { catchError, getAsyncData } from './axios.service';
import {
  ICredentials,
  ResponseSignIn,
} from '../module/SignIn/signin.interface';
import { AlertType } from '../context/snackbar-context';
import {
  delDataFromLocalStorage,
  setDataToLocalStorage,
} from '../helpers/methods';

export const signIn = async (
  credentials: ICredentials,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
): Promise<boolean> => {
  let res;
  try {
    res = await getAsyncData({
      url: `/auth/signin`,
      type: 'post',
      req: credentials,
    });

    if (res.error.errorCode === 201) {
      setDataToLocalStorage('name', credentials.login);
      setDataToLocalStorage('param', res.data.isAdmin);
      setDataToLocalStorage('token', res.data.token as ResponseSignIn);
      showMessage('success', 'Ласкаво просимо!');
      setAuthenticated(true);
      res = true;
    } else {
      showMessage('error', res.error.errorDescription);
      res = false;
    }
  } catch (e) {
    catchError(e, showMessage);
  }

  return !!res;
};

export const logout = (
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  delDataFromLocalStorage('name');
  delDataFromLocalStorage('token');
  delDataFromLocalStorage('param');
  delDataFromLocalStorage('firm');
  setAuthenticated(false);
  showMessage('info', 'До нової зустрічі!');
};
