import React, { useEffect, useMemo } from 'react';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import SignIn from './module/SignIn/SignIn';
import { AuthContext, Page } from './context/main-context';
import { createTheme } from '@mui/material';
import { ukUA } from '@mui/material/locale';
import { useAuthenticated } from './hooks/authenticate.hook';
import MposKeys from './module/MposKeys/MposKeys';
import './style/main.css';
import SnackBarContext from './context/snackbar-context';
import { useSnackbar } from './hooks/snackbar.hook';
import Tooltip from './components/UI/Tooltip/Tooltip';
import { getDataFromLocalStorage } from './helpers/methods';
import Users from './module/Users/Users';
import CSO from './module/CSO/CSO';
import RRO from './module/RRO/RRO';

//@ts-ignore
declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme(
  {
    palette: {
      mode: 'light',
      action: {
        active: '#fff',
      },

      primary: {
        main: '#749de1',
        light: '#f7f9fc',
        dark: '#6478a1',
        contrastText: '#d3d3d3',
      },
      secondary: {
        main: '#0a89e3',
        light: '#6eff81',
        dark: '#064f80',
        contrastText: '#fdfdfd',
      },
      warning: {
        main: '#d5d5d5',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
      contrastThreshold: 3,
      divider: '#000',
    },
    typography: {
      fontFamily: 'Comfortaa-Regular',
    },
  },
  ukUA,
);

function App() {
  const authContext = useAuthenticated();
  const snackbar = useSnackbar();

  useEffect(() => {
    const token = getDataFromLocalStorage('token');
    if (token) {
      authContext.setAuthenticated(true);
      authContext.setCurrentPage(Page.MAIN);
    }
  }, []);

  const page = useMemo(() => {
    if (!authContext.authenticated) return <SignIn />;
    switch (authContext.page) {
      case Page.LOGIN:
        return <SignIn />;
      case Page.MAIN:
        return <MposKeys />;
      case Page.ADMIN:
        return <Users />;
      case Page.CSO:
        return <CSO />;
      case Page.RRO:
        return <RRO />;
    }
  }, [authContext.page, authContext.authenticated]);

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={authContext}>
        <SnackBarContext.Provider value={snackbar}>
          <SnackbarProvider maxSnack={3} autoHideDuration={10000}>
            <Tooltip />
            {page}
          </SnackbarProvider>
        </SnackBarContext.Provider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
