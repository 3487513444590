import { IRow } from '../Rows/Rows';
import { useMemo } from 'react';
import { IColumn } from '../Columns/Columns';
import { Box, IconButton, TableCell } from '@mui/material';
import { Bool } from './FetchFormats/Bool';
import LinkOpacity from './FetchFormats/LinkOpacity';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCustomTableContext } from '../../helpers/table.context';
import CellTextEditor from './FetchFormats/CellTextEditor';
import { ISingleRow } from '../../interface';
import DeleteRow from './FetchFormats/DeleteRow';
import moment from 'moment';
import CellPhoneEditor from './FetchFormats/CellPhoneEditor';
import CellCombobox from './FetchFormats/CellCombobox';
import CellComboboxMultiply from './FetchFormats/CellComboboxMultiply';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MergeRowInOneColumn from './FetchFormats/MergeRowInOneColumn';
import DownloadBigText from './FetchFormats/DownloadBigText';
import SaveFile from './FetchFormats/SaveFile';
import HrefLink from './FetchFormats/HrefLink';

interface ICell {
  column: IColumn;
  row: IRow;
  /**
   * Тип для открытия внутренней таблицы
   */
  collapsible?: {
    isOpen: boolean;
    handleToggleCollapsibleTable(): void;
  };
}

export default function FormatCell({
  column,
  row,
  collapsible = {
    isOpen: false,
    handleToggleCollapsibleTable: () => console.log('Empty callback!'),
  },
}: ICell) {
  const { param } = useCustomTableContext();

  const renderCell = useMemo(() => {
    const value = row[column.field];
    const {
      cell: { format },
    } = column;

    switch (format) {
      case 'empty': {
        return <></>;
      }

      case 'boolean': {
        const { field } = column;
        return row[field] ? <CheckIcon /> : <CloseIcon />;
      }

      case 'terminalId': {
        return row[column.field] === -1
          ? 'Невідомий термінал'
          : row[column.field];
      }

      case 'last-date-time-connect': {
        return row[column.field];
      }

      case 'diff-last-date-time-connect-from-current': {
        return moment(row.lastConnectDate)
          .add(72, 'hour')
          .diff(moment(), 'hours');
      }

      case 'hrefLinkFirm': {
        return (
          <HrefLink
            link={`/?firmId=${row.FIRM_ID}`}
            label={row[column.field]}
          />
        );
      }

      case 'saveFileAndCopyToClipboardInfo': {
        const {
          field,
          cell: { isPutText, handleDownload, handleCopyClipboard },
        } = column;
        const handlerDownload = () => {
          handleDownload(row);
        };

        const handlerCopyClipboard = () => {
          handleCopyClipboard(row);
        };

        return (
          <SaveFile
            text={row[field]}
            isPutText={isPutText}
            handlerDownload={handlerDownload}
            handlerCopyClipboard={handlerCopyClipboard}
          />
        );
      }

      case 'bigText': {
        const {
          cell: { maxCount, btnTitle },
        } = column;
        return row[column.field].length > maxCount ? (
          <DownloadBigText btnText={btnTitle} text={row[column.field]} />
        ) : (
          row[column.field]
        );
      }

      case 'day-out-of-range': {
        if (row[column.field] === '2200-01-01T00:00:00.000Z') {
          return ' - ';
        }
        return moment(row[column.field]).format('DD.MM.YYYY HH:mm');
      }

      case 'minute-from-second': {
        const { field } = column;

        if (row.dateEndMinGauging === '2200-01-01T00:00:00.000Z') {
          return ' - ';
        }

        const min = parseInt(row[field]);
        const hour = Math.trunc(min / 60);
        const minTxt = min - hour * 60;
        return `${hour < 10 ? '0' + hour : hour}:${
          minTxt < 10 ? '0' + minTxt : minTxt
        }`;
      }

      case 'date-time-request': {
        return moment(row.fuels[0].dateTimeGauging).format(
          'DD.MM.YYYY HH:mm:ss',
        );
      }

      case 'merge-rows-in-array': {
        const {
          cell: { searchingArray, field, round },
          alignData = 'center',
        } = column;
        return (
          <MergeRowInOneColumn
            row={row}
            searchingArray={searchingArray}
            field={field}
            round={round}
            alignData={alignData}
          />
        );
      }

      case 'switch-account':
        return column.cell.onToggleSwitch ? (
          <Bool
            row={row}
            onToggleSwitch={column.cell.onToggleSwitch}
            value={value}
          />
        ) : (
          <>You have no callback for this function</>
        );

      case 'switch-card':
        return column.cell.onToggleSwitch ? (
          <Bool
            row={row}
            onToggleSwitch={column.cell.onToggleSwitch}
            value={value}
          />
        ) : (
          <>You have no callback for this function</>
        );

      case 'link-opacity-card': {
        const {
          cell: { label },
        } = column;

        const accountId = param.accountId ? param.accountId : 0;
        const holderId = param.holderId ? param.holderId : 0;

        return (
          <LinkOpacity
            link={`/holder/${holderId}/account/${accountId}/card/${row.cardId}/setting`}
            label={`${label}`}
          />
        );
      }

      case 'text-edit-limits': {
        const { field } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, limitAmount: row[field] };
          return column.cell.onChangeField
            ? column.cell.onChangeField({ row: newRow })
            : false;
        };

        return (
          <CellTextEditor
            type={'number'}
            row={row}
            field={field}
            onCommitChanges={onCommitChange}
          />
        );
      }

      case 'text-edit': {
        const { field } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return column.cell.onChangeField
            ? column.cell.onChangeField({ row: newRow })
            : false;
        };

        return (
          <CellTextEditor
            type={'text'}
            row={row}
            field={field}
            onCommitChanges={onCommitChange}
          />
        );
      }

      case 'text-edit-phone': {
        const { field } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return column.cell.onChangeField
            ? column.cell.onChangeField({ row: newRow })
            : false;
        };

        return (
          <CellPhoneEditor
            row={row}
            field={field}
            onCommitChanges={onCommitChange}
          />
        );
      }

      case 'text-edit-email': {
        const { field } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return column.cell.onChangeField
            ? column.cell.onChangeField({ row: newRow })
            : false;
        };

        return (
          <CellTextEditor
            type={'email'}
            row={row}
            field={field}
            onCommitChanges={onCommitChange}
          />
        );
      }

      case 'combobox-edit': {
        const {
          field,
          cell: { array },
        } = column;
        /* Корректируем значимое поле amount */
        const onCommitChange = async ({ row }: ISingleRow) => {
          const newRow = { ...row, [field]: row[field] };
          return column.cell.onChangeField
            ? column.cell.onChangeField({ row: newRow })
            : false;
        };

        return (
          <CellCombobox
            value={row[field]}
            row={row}
            onCommitChanges={onCommitChange}
            array={array}
          />
        );
      }

      case 'combobox-edit-multiply': {
        const {
          cell: { array, selectField, selectId, optionName },
        } = column;

        const selected = row[selectField].map((f: any) => f[selectId]);

        return (
          <CellComboboxMultiply
            selected={selected}
            fieldId={selectId}
            optionName={optionName}
            array={array}
          />
        );
      }

      case 'icon-delete': {
        const {
          cell: { labelConfirm },
        } = column;
        return (
          <DeleteRow
            labelConfirm={labelConfirm}
            onConfirmDelete={column.cell.onClickDelete}
            row={row}
          />
        );
      }

      case 'link-opacity-account': {
        const {
          cell: { label },
        } = column;
        const holderId = param.holderId ? param.holderId : 0;
        return (
          <LinkOpacity
            link={`/holder/${holderId}/account/${row.id}/cards`}
            label={`${label}(${row.numbersActiveCards})`}
          />
        );
      }

      case 'icon-before-text': {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <column.cell.icon sx={{ marginRight: '.3rem' }} />
            {row[column.field]}
          </Box>
        );
      }

      case 'date-time': {
        const {
          cell: { formatDate },
        } = column;
        const format = formatDate ? formatDate : 'DD.MM.YYYY HH:mm';
        return moment(row[column.field]).format(format);
      }

      case 'join': {
        const {
          cell: { fields },
        } = column;
        const findFields = fields.reduce(
          (acc: any, field: string | number) => [...acc, row[field]],
          [],
        );
        return findFields.join(' ');
      }

      case 'collapse': {
        const { isOpen, handleToggleCollapsibleTable } = collapsible;
        return (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleToggleCollapsibleTable}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        );
      }

      case 'numericalDigit': {
        return row[column.field].toLocaleString();
      }

      default:
        return <>{row[column.field]}</>;
    }
  }, [column, row, collapsible, param.accountId, param.holderId]);

  let style = {};

  switch (column.cell.format) {
    case 'terminalId': {
      style =
        row[column.field] === -1
          ? {
              backgroundColor: '#ec9a9a',
            }
          : {};
      break;
    }
    case 'last-date-time-connect': {
      let color =
        moment().diff(moment(row[column.field]), 'days') >= 2 && '#eedf67';
      color =
        moment().diff(moment(row[column.field]), 'days') >= 3 && '#ec9a9a';
      style = {
        backgroundColor: color,
      };
      break;
    }
    case 'merge-rows-in-array': {
      style = {
        cursor: 'pointer',
        padding: '.1rem .5rem',
      };
      break;
    }
    default: {
      style = {
        cursor: 'pointer',
        padding: 0,
      };
    }
  }
  return (
    <TableCell style={{ ...style }} align={column.alignData}>
      {renderCell}
    </TableCell>
  );
}
