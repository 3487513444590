import { createContext } from 'react';

export type AlertType = 'default' | 'error' | 'success' | 'warning' | 'info';

export interface AlertSnackBar {
  type: AlertType;
  message: string;
  showMessage: (type: AlertType, message: string) => void;
}

export const SnackBarContext = createContext<AlertSnackBar>({
  type: 'success',
  message: '',
  showMessage: () => {},
});

export default SnackBarContext;
