import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { ReactElement } from "react";

const Container = styled( Box )( () => ( {
	backgroundColor: "inherit",
	padding: "0rem",
	height: "fit-content",
	borderRadius: "10px"
} ) );

interface IClientDetail {
	show: boolean;
	children: ReactElement<any, any> | any;
	direction: 'left' | 'right' | 'up' | 'down';
	sx?: React.CSSProperties;
}

export default function InnerContainer ( { show, children, sx = {}, direction = 'left' }: IClientDetail ) {

	return <>
		<Slide direction={ direction } in={ show } mountOnEnter unmountOnExit>
			<Container sx={ { ...sx } }>
				{ children }
			</Container>
		</Slide>
	</>
}
