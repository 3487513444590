import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';
import { useEffect, useState } from 'react';

// @ts-ignore
import * as locales from 'react-date-range/dist/locale';
import InputFilled from '../Input/InputFilled';
import moment, { Moment } from 'moment';
import { Box, Button, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

const WrapperBoxCalendar = styled(Box)(({ theme }) => ({
  right: -15,
  position: 'absolute',
  padding: '.2rem',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '5px',
  zIndex: 100,
}));

const WrapperBoxButtonCalendar = styled(Box)(() => ({
  margin: '.2rem 0',
  display: 'flex',
  justifyContent: 'flex-end',
}));

interface IFilterDate {
  defaultDate: string | Moment;

  onChange(dateRange: any): void;
}

export default function FilterDate({
  onChange,
  defaultDate,
  ...other
}: IFilterDate) {
  const [date, setDate] = useState<Date>(new Date());
  const [isShowPicker, setShowPicker] = useState(false);
  const [isChecked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    defaultDate &&
      defaultDate !== '2200-01-01 00:00' &&
      setDate(moment(defaultDate).toDate());
  }, [defaultDate]);

  const handleShowDatePicker = () => {
    setShowPicker((prev) => !prev);
  };

  const handleChangeDate = (date: Date) => {
    setDate(date);
  };

  const handleApply = () => {
    onChange(isChecked ? moment(date) : '2200-01-01 00:00');
    setShowPicker(false);
  };

  const handleChangeCheckbox = (e: any) => {
    const {
      target: { checked },
    } = e;
    setChecked(checked);
    checked ? onChange(moment(date)) : onChange('2200-01-01 00:00');
  };

  const handleCancelDate = () => {
    setDate(new Date());
    setShowPicker(false);
  };

  return (
    <Box {...other} style={{ position: 'relative', minWidth: '140px' }}>
      <Box sx={{ position: 'relative' }}>
        <InputFilled
          label={`Дата`}
          type={'text'}
          value={moment(date).format('DD.MM.YYYY')}
          onClick={handleShowDatePicker}
          sx={{ caretColor: 'transparent' }}
        />
        <Checkbox
          checked={isChecked}
          onChange={handleChangeCheckbox}
          size="small"
          sx={{ position: 'absolute', right: 0, top: 10 }}
        />
      </Box>

      {isShowPicker && (
        <WrapperBoxCalendar>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              color: '#ccc',
              zIndex: 0,
            }}
            onClick={handleCancelDate}
          />
          <Box
            sx={{
              display: 'flex',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <Calendar
              onChange={handleChangeDate}
              locale={locales['uk']}
              date={date}
            />
          </Box>
          <WrapperBoxButtonCalendar>
            <Button
              variant={'outlined'}
              color={'warning'}
              onClick={handleApply}
            >
              {`Обрати`}
            </Button>
          </WrapperBoxButtonCalendar>
        </WrapperBoxCalendar>
      )}
    </Box>
  );
}
