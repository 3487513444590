/** @format */

import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AuthContext from '../../context/main-context';
import SnackbarContext from '../../context/snackbar-context';
import { getFirms, getKeys } from '../../services/mpos-keys.service';
import InnerContainer from '../../components/Common/InnerContainer/InnerContainer';
import InputSearchLine from '../../components/UI/Input/InputSearchLine';
import { filterObject } from '../../helpers/methods';
import { getColumns } from './mpos-keys.column';
import CustomTable from '../../components/UI/CustomTable';
import MainLayout from '../../components/Layout/MainLayout';
import Loading from '../../components/UI/Loading/Loading';
import { Filter, Firm, Key } from './mpos-keys.interface';
import ComboBox from '../../components/UI/base/ComboBox';
import { Button, Grid, Tooltip } from '@mui/material';
import FilterDate from '../../components/UI/base/FilterDate';
import { Moment } from 'moment';
import { useLocation } from 'react-router-dom';

const KeyInfo = lazy(() => import('./KeyInfo'));

const initialFilter: Filter = {
  FIRM_ID: 0,
  DATE_CREATE: '2200-01-01 00:00',
  SEARCH_STR: '',
};

export default function MposKeys() {
  const { setAuthenticated, page } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);
  const [isLoading, setLoading] = useState(false);
  const [keys, setKeys] = useState([]);
  const [firms, setFirms] = useState<Firm[]>([]);

  const [isKeyInfoOpen, setKeyInfoOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Key>();

  const [filter, setFilter] = useState<Filter>(initialFilter);

  const location = useLocation();

  useEffect(() => {
    getFirmsFromServer().then();
  }, []);

  useEffect(() => {
    if (location.search && location.search !== '') {
      const param = new URLSearchParams(location.search);

      const pFilter: Filter = initialFilter;

      pFilter.FIRM_ID = parseInt(param.get('firmId') || '0', 10);

      pFilter.FIRM_ID && setFilter({ ...initialFilter, ...pFilter });

      getKeysFromServer().then();
    }
  }, [location.search]);

  const getKeysFromServer = useCallback(async () => {
    try {
      setLoading(true);
      if (location.search) {
        let newHrefUrl = `?firmId=${filter.FIRM_ID}`;
        window.history.pushState(null, '', newHrefUrl);
      }
      const keys = await getKeys(filter, showMessage, setAuthenticated);
      setKeys(keys);
    } finally {
      setLoading(false);
    }
  }, [filter, setAuthenticated, showMessage]);

  const getFirmsFromServer = useCallback(async () => {
    try {
      const firms = await getFirms(showMessage, setAuthenticated);
      firms && setFirms(firms);
    } catch {}
  }, []);

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let filteredArr = filterObject({
      arr: keys || [],
      fields: ['FIRM_NAME', 'POSNUMBER', 'REMARK'],
      searchStr: filter.SEARCH_STR,
    });

    filteredArr = filteredArr.length > 0 ? filteredArr.slice(0, 100) : [];

    /* Применение фильтра */
    return { arr: filteredArr, count: 0 };
  }, [keys, filter.SEARCH_STR]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns({ keys, showMessage, setAuthenticated });
  }, [keys, setAuthenticated, showMessage]);

  const handleRefreshPage = () => {
    getKeysFromServer().then();
  };

  const handleShowKeyInfo = (row: any) => {
    setSelectedRow(row as Key);
    setKeyInfoOpen(true);
  };

  const handleChangeFirm = (FIRM_ID: number) => {
    setFilter((prev) => ({ ...prev, FIRM_ID }));
  };

  const handleChangeDate = (DATE_CREATE: Moment) => {
    setFilter((prev) => ({ ...prev, DATE_CREATE }));
  };

  const handleChangeSearchStr = (SEARCH_STR: string) => {
    setFilter((prev) => ({ ...prev, SEARCH_STR }));
  };

  return (
    <>
      <MainLayout>
        <InnerContainer direction={'left'} show={true}>
          <Grid
            container
            spacing={3}
            sx={{
              paddingTop: '1rem',
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Grid item xs={12} sm={12} md={3}>
              <InputSearchLine
                placeholder={`Пошуковий рядок`}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeSearchStr(e.currentTarget.value);
                }}
                value={filter.SEARCH_STR}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ComboBox
                textStyle={{ fontWeight: '700' }}
                data={firms}
                fieldLabelOption={'NAME'}
                label={`Фірми`}
                fnOnChange={handleChangeFirm}
                callbackType={'onlyId'}
                defaultValue={filter.FIRM_ID}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Tooltip title={'Початкова дата створення'} placement={'top'}>
                <FilterDate
                  onChange={handleChangeDate}
                  defaultDate={filter.DATE_CREATE}
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Button
                variant={'outlined'}
                onClick={handleRefreshPage}
                color="primary"
                sx={{ width: '100%', height: '55px' }}
              >
                Пошук
              </Button>
            </Grid>
          </Grid>
          <CustomTable
            actionButton={{
              refreshIcon: true,
              refreshHint: 'Оновити',
              refreshBtnClick: handleRefreshPage,
            }}
            sx={{
              '& .MuiTableCell-root': {
                fontSize: '.75rem',
              },
              '& .MuiTableBody-root .MuiTableCell-root': {
                borderLeft: '1px solid #ccc',
              },
              '& .MuiTableBody-root .MuiTableCell-root:nth-last-of-type(-n+1)':
                {
                  borderRight: '1px solid #ccc',
                },
            }}
            onDoubleClick={handleShowKeyInfo}
            isSimple
            title={`Реєстраційні ключі Mpos`}
            rows={rows.arr}
            columns={columns}
            isLoading={isLoading}
            count={rows.count}
            labelEmptyRows={`Рядки відсутні`}
          />
        </InnerContainer>
      </MainLayout>
      <Suspense fallback={<Loading type={'fullScreen'} />}>
        {isKeyInfoOpen && (
          <KeyInfo
            isOpen={isKeyInfoOpen}
            row={selectedRow}
            onClose={() => setKeyInfoOpen(false)}
          />
        )}
      </Suspense>
    </>
  );
}
