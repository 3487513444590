import TableCell from '@mui/material/TableCell';
import {
  IOrderBy,
  ISingleRow,
  TAlign,
  TFormatColumn,
  TTypeCollapsibleColumn,
} from '../../interface';
import FormatColumn from '../Cell/FormatColumn';
import { styled } from '@mui/material/styles';

import { Box, TableSortLabel, Tooltip } from '@mui/material';
import { useCustomStyles } from '../../style/global';

export interface IColumn {
  field: string;
  width?: number;
  align?: TAlign;
  alignData?: TAlign;
  formatHeader?: TFormatColumn;
  cell: {
    format: TFormatColumn;
    type?: TTypeCollapsibleColumn;
    /**
     *  Если тип "switch" существует колбэк переключателя
     */
    onToggleSwitch?({ row }: ISingleRow): Promise<boolean>;
    /**
     * Если это "text-edit" существует колбэк изменения текстовой строки
     */
    onChangeField?({ row }: ISingleRow): Promise<boolean>;
    /**
     *  Если тип 'delete' нажатие на удалить
     */
    onClickDelete?({ row }: ISingleRow): Promise<boolean>;
    [key: string]: any;
  };
  label: string;
  hint?: string;
  sortable?: boolean;
}

interface IColumns {
  /**
   * Заголовки колонок
   */
  columns: IColumn[];
  /**
   * Сортировка
   */
  orderBy: IOrderBy;

  /**
   * Колбэк смены поля сортировки
   */
  onChangeOrderBy?(orderBy: IOrderBy): void;
}

const TableCellBorder = styled(TableCell)(() => ({
  position: 'relative',

  '&:after': {
    content: '""',
    position: 'absolute',
    top: '30%',
    bottom: '30%',
    right: 0,
    height: '40%',
    borderRight: '2px solid rgb(224, 224, 224)',
  },
}));

export default function Columns({
  columns,
  orderBy,
  onChangeOrderBy,
}: IColumns) {
  const classes = useCustomStyles();

  const handleRequestSort = (field: string) => {
    onChangeOrderBy &&
      onChangeOrderBy({
        sortField: field,
        sortingType: orderBy.sortingType === 'asc' ? 'desc' : 'asc',
      });
  };

  return (
    <>
      {columns.map((column) => (
        <Tooltip
          key={column.field}
          title={column.hint ? column.hint : column.label}
          placement="top"
        >
          <TableCellBorder
            className={classes.titleDefault}
            sx={{
              '& .MuiButtonBase-root': { position: 'initial' },
              width: column.width,
              textAlign: column.align,
              cursor: 'pointer',
            }}
          >
            {
              /* Проверяем сортируется ли поле */
              column.sortable ? (
                /* Сортируется */
                <>
                  <TableSortLabel
                    sx={{
                      '& .MuiSvgIcon-root': {
                        position: 'absolute',
                        right: '10px',
                      },
                    }}
                    active={orderBy.sortField === column.field}
                    direction={orderBy.sortingType}
                    onClick={() => {
                      handleRequestSort(column.field);
                    }}
                  >
                    <FormatColumn column={column} />
                  </TableSortLabel>
                </>
              ) : (
                /* Не сортируется просто  форматируем если надо */
                <Box>
                  <FormatColumn column={column} />
                </Box>
              )
            }
          </TableCellBorder>
        </Tooltip>
      ))}
    </>
  );
}
