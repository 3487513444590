import { TableCell, TableRow, Typography } from '@mui/material';
import { IColumn } from '../Columns/Columns';
import FormatCell from '../Cell/FormatCell';
import CollapsibleRow from './CollapsibleRow';
import { styled } from '@mui/material/styles';

const StyleCell = styled(TableCell)(() => ({
  textAlign: 'center',
  border: 'none !important',
  padding: '3rem 0',
}));

export interface IRow {
  id: number | string;

  [key: string]: any;
}

export interface IRows {
  selectedRow: IRow | undefined;
  columns: IColumn[];
  rows: IRow[];
  labelEmptyRows?: string;

  onDoubleClick?({ row }: IRow): void;

  onClick?({ row }: IRow): void;
}

export default function Rows({
  rows,
  columns,
  labelEmptyRows,
  onDoubleClick,
  onClick,
  selectedRow,
}: IRows) {
  return (
    <>
      {
        /* Проверяем кол-во строк на странице */
        rows.length <= 0 ? (
          <TableRow>
            <StyleCell colSpan={25}>
              <Typography>{labelEmptyRows}</Typography>
            </StyleCell>
          </TableRow> /* Берем отправленную строку */
        ) : (
          rows.map((row, idx) => {
            /* Проверяем есть ли формат колонки collapse */
            const columnsWithType = columns.findIndex(
              (column) => column.cell.format === 'collapse',
            );
            /* Находим в каком типе подчиенная таблица */
            const type =
              columnsWithType !== -1
                ? columns[columnsWithType].cell.type
                : 'default';

            const rowStyle =
              selectedRow?.ID === row.ID && !!selectedRow?.ID
                ? {
                    backgroundColor: 'rgba(0,0,0, 0.3)',
                  }
                : {};

            return (
              /* Проверяем есть ли расскрывающаяся строка */
              type !== 'default' ? (
                <CollapsibleRow
                  key={`${row.id}_${idx}`}
                  row={row}
                  columns={columns}
                  type={type}
                />
              ) : (
                <TableRow
                  hover
                  key={`${row.id}_${idx}`}
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                  onDoubleClick={onDoubleClick && onDoubleClick.bind(null, row)}
                  onClick={onClick && onClick.bind(null, row)}
                  style={rowStyle}
                >
                  {
                    /* И исходя из формата колонки каждой строки формируем вывод, на основании данных о колонках */
                    columns.map((column) => {
                      return (
                        <FormatCell
                          key={column.field}
                          column={column}
                          row={row}
                        />
                      );
                    })
                  }
                </TableRow>
              )
            );
          })
        )
      }
    </>
  );
}
