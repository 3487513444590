import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

interface ILinkOpacity {
  link: string;
  label: string;
}

const LinkLabel = styled(Typography)((props) => ({
  fontSize: 'inherit',

  '&:hover': { opacity: '1', color: props.theme.palette.secondary.main },
}));

const StyleNavLink = styled(NavLink)(() => ({
  textDecoration: 'none',
  color: 'inherit',
}));

export default function HrefLink({ link, label }: ILinkOpacity) {
  return (
    <StyleNavLink to={link} target={'_blank'}>
      <LinkLabel>{label}</LinkLabel>{' '}
    </StyleNavLink>
  );
}
