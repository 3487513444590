import { createContext, useContext } from 'react';

type TContext = {
	param: any;
}

export const CustomTableContext = createContext<TContext>( { param: undefined } );

export const useCustomTableContext = () => useContext( CustomTableContext )

export default CustomTableContext;