import { Box, Grid } from '@mui/material';

import React from 'react';
import { useCustomStyles } from '../../../style/global';
import Loading from '../Loading/Loading';

export interface IGroupTitle {
  /**
   * Заголовок
   */
  title: string;
  /**
   * Индикация загрузки
   */
  isLoading?: boolean;
  /**
   * Кастомные стили
   */
  sx?: React.CSSProperties;
}

export default function Title({
  title,
  isLoading = false,
  sx = {},
}: IGroupTitle) {
  const classes = useCustomStyles();

  return (
    <Grid item sx={{ ...sx }}>
      <Box style={{ fontSize: '1.5rem' }} className={classes.titleDefault}>
        {isLoading ? <Loading type={'small'} /> : title}
      </Box>
    </Grid>
  );
}
