import { IRow } from "../../Rows/Rows";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TAlign } from "../../../interface";
import { makeId } from "../../../helpers/methods";

const CustomTableBody = styled( TableBody )( () => ( {
	"& tr td": {
		borderRight: "none !important",
		borderLeft: "none !important",
		borderColor: "#ccc"
	},
	"& tr:nth-last-of-type( -n + 1 ) td": {
		border: "none"
	}
} ) );

interface IMergeRowInOneColumn {
	row: IRow;
	searchingArray: string;
	round?: boolean;
	field: string;
	alignData: TAlign;
}

/**
 *  Объеденияем строки массива в одну ячейку
 * @param row - строка
 * @param searchingArray - имя искомого массива
 * @param round -  если это число, нужно ли округлять ячейку
 * @param field - название поля по-которому будем объединять
 * @param alignData - выравнивание данных
 */
export default function MergeRowInOneColumn ( {
	                                              row,
	                                              searchingArray,
	                                              field,
	                                              round = false,
	                                              alignData
                                              }: IMergeRowInOneColumn ) {

	const fields = row[ searchingArray ].map( ( item: any ) => item[ field ] );

	return (
		<Table size="small">
			<CustomTableBody>
				{
					fields.map( ( field: any ) => (
						<TableRow id={ makeId() }>
							<TableCell align={ alignData }
							           style={ { padding: "0 .2rem" } }>{ round ? field.toFixed( 2 ) : field }</TableCell>
						</TableRow>
					) )
				}
			</CustomTableBody>
		</Table>
	)

}
