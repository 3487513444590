import { useCallback, useState } from 'react';
import { AlertSnackBar, AlertType } from '../context/snackbar-context';

export const useSnackbar = () => {
  const [snack, setSnack] = useState<Omit<AlertSnackBar, 'showMessage'>>({
    type: 'success',
    message: '',
  });

  const showMessage = useCallback((type: AlertType, message: string): void => {
    setSnack({ message, type });
    setTimeout(() => {
      setSnack({ message: '', type: 'success' });
    }, 1000);
  }, []);

  return {
    type: snack.type,
    message: snack.message,
    showMessage,
  };
};
