import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import SnackbarContext from '../../../context/snackbar-context';

/**
 * Модуль подсказок
 */
export default function Tooltip() {
  const { enqueueSnackbar } = useSnackbar();

  const { message, type } = useContext(SnackbarContext);

  useEffect(() => {
    if (message && message?.length > 0) {
      enqueueSnackbar(message, { variant: type });
    }
  }, [message, enqueueSnackbar, type]);

  return <></>;
}
