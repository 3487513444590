import MainLayout from '../../components/Layout/MainLayout';
import { Box, Grid, Typography } from '@mui/material';
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { deleteUser, getUsers } from '../../services/admin.service';
import { User } from './user.interface';
import AuthContext from '../../context/main-context';
import SnackbarContext from '../../context/snackbar-context';
import { getColumns } from './user.column';
import { filterObject } from '../../helpers/methods';
import InputSearchLine from '../../components/UI/Input/InputSearchLine';
import InnerContainer from '../../components/Common/InnerContainer/InnerContainer';
import CustomTable from '../../components/UI/CustomTable';
import Loading from '../../components/UI/Loading/Loading';
import { IRow } from '../../components/UI/CustomTable/components';
import Modal from '../../components/UI/Modal/Modal';

const UserEditor = lazy(() => import('./UserEditor'));

export default function Users() {
  const { setAuthenticated, page } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);
  const [isLoading, setLoading] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const [isOpenConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [users, setUsers] = useState<User[]>();
  const [selectedRow, setSelectedRow] = useState<User | undefined>();
  /* Строка поиска по всем полям таблицы */
  const [searchStr, setSearchStr] = useState('');

  useEffect(() => {
    getUserFromServer().then();
  }, []);

  const getUserFromServer = async () => {
    setLoading(true);
    try {
      const users = await getUsers(showMessage, setAuthenticated);
      setUsers(users);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let filteredArr = filterObject({
      arr: users || [],
      fields: ['LOGIN', 'NAME', 'LIST_FIRMS'],
      searchStr,
    });

    filteredArr = filteredArr.length > 0 ? filteredArr.slice(0, 100) : [];

    /* Применение фильтра */
    return { arr: filteredArr, count: 0 };
  }, [users, searchStr]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns();
  }, [users]);

  const handleAddUser = () => {
    setSelectedRow(undefined);
    setEditOpen(true);
  };

  const handleEditUser = (row: User) => {
    setSelectedRow(row);
    setEditOpen(true);
  };

  const handleDeleteUser = async (row: IRow) => {
    if (selectedRow) {
      setOpenConfirmDeleteModal(true);
    } else {
      showMessage('warning', 'Користувач не обран');
    }
  };

  const handleClick = (row: any) => {
    setSelectedRow(row);
  };

  const submitDeleteRole = async () => {
    const userId = selectedRow && selectedRow?.USER_ID;
    const res = await deleteUser(userId || -1, showMessage, setAuthenticated);
    if (res) {
      await getUserFromServer();
      setOpenConfirmDeleteModal(false);
    }
  };

  const handleCloseUserEditor = async () => {
    await getUserFromServer();
    setEditOpen(false);
  };

  return (
    <>
      <MainLayout>
        <InnerContainer direction={'left'} show={true}>
          <Grid
            sx={{
              paddingTop: '1rem',
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <InputSearchLine
              placeholder={`Пошуковий рядок`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchStr(e.currentTarget.value);
              }}
            />
          </Grid>
          <CustomTable
            actionButton={{
              addIcon: true,
              addHint: 'Додати',
              addBtnClick: handleAddUser,

              refreshIcon: true,
              refreshHint: 'Оновити',
              refreshBtnClick: getUserFromServer,

              delIcon: true,
              delHint: 'Видалити',
              delBtnClick: handleDeleteUser.bind(null),
            }}
            sx={{
              '& .MuiTableCell-root': {
                fontSize: '.75rem',
              },
              '& .MuiTableBody-root .MuiTableCell-root': {
                borderLeft: '1px solid #ccc',
              },
              '& .MuiTableBody-root .MuiTableCell-root:nth-last-of-type(-n+1)':
                {
                  borderRight: '1px solid #ccc',
                },
            }}
            onClick={handleClick}
            onDoubleClick={handleEditUser}
            isSimple
            title={`Користувачі системи`}
            rows={rows.arr}
            columns={columns}
            isLoading={isLoading}
            count={rows.count}
            labelEmptyRows={`Рядки відсутні`}
          />
        </InnerContainer>
      </MainLayout>
      <Suspense fallback={<Loading type={'fullScreen'} />}>
        {isEditOpen && (
          <UserEditor
            isOpen={isEditOpen}
            row={selectedRow}
            onClose={handleCloseUserEditor}
          />
        )}
        {isOpenConfirmDeleteModal && (
          <Modal
            handleBtnOk={submitDeleteRole}
            isOpen={isOpenConfirmDeleteModal}
            handleBtnCancel={() => setOpenConfirmDeleteModal(false)}
          >
            <Box sx={{ width: '300px' }}>
              <Typography>
                {` Ви дійсно бажаете відалити користувача "${selectedRow?.NAME}"?`}
              </Typography>
            </Box>
          </Modal>
        )}
      </Suspense>
    </>
  );
}
