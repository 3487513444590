import { styled } from '@mui/material/styles';
import { Box, CardMedia } from '@mui/material';
import InputFilled from '../../components/UI/Input/InputFilled';
import { LoadingButton } from '@mui/lab';

export const Container = styled(Box)((props) => ({
  position: 'relative',
  display: 'flex',
  maxWidth: '800px',
  minHeight: '500px',
  backgroundColor: 'rgba(248,242,237,0.88)',
  borderRadius: '10px',
  overflow: 'hidden',
  zIndex: 1,
  [props.theme.breakpoints.down('md')]: {
    height: '100vh',
    flexDirection: 'column',
    borderRadius: 'inherit',
  },
}));

export const ItemView = styled(Box)(() => ({
  position: 'relative',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CredentialInput = styled(InputFilled)(() => ({
  marginTop: '0.5rem',
  width: '80%',
}));

export const SubmitBtn = styled(LoadingButton)(() => ({
  width: '100%',
  marginTop: '1.5rem',
  padding: '.7rem 0',
}));

export const GlobalContainer = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const WrapperCardMedia = styled(CardMedia)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  height: '100vh',
  zIndex: 0,
}));
