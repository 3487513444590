/**
 * Запись в локальное хранилище
 * @param key
 * @param value
 */

export function setDataToLocalStorage(key: string, value: any) {
  localStorage.setItem(key, value);
}

/**
 * Получение данных из локального хранилища
 * @param key
 */

export function getDataFromLocalStorage(key: string | undefined) {
  return key && localStorage.getItem(key);
}

/**
 * Удаление объектов из локального хранилища
 * @param key
 */
export function delDataFromLocalStorage(key: string) {
  localStorage.removeItem(key);
}

interface IFilterObject<T> {
  arr: T[];
  fields: string[];
  searchStr: string;
}

interface IFilterObjectNew<T> {
  arr: T[];
  searchStr: string;
}

/**
 * Поиск по нескольким полям в массиве
 * @param arr - Исходный массив
 * @param fields - поля по которым необходим поиск ['field']
 * @param searchStr - строка поиска
 */
export function filterObject<T>({
  arr,
  fields = [''],
  searchStr,
}: IFilterObject<T>) {
  const findArr = arr.filter((item: any) => {
    let isFindItem = false;
    for (const field of fields) {
      /* Если поле существует и в него входит подстрока */
      isFindItem =
        (item[field] as string) &&
        item[field].toLowerCase().includes(searchStr.toLowerCase());
      if (isFindItem) break;
    }
    return isFindItem;
  });

  // const findArr = arr?.reduce((acc: any, item: any) => {
  //   let indexField = '';
  //
  //   fields.forEach((field) => {
  //     if (item[field]) indexField += item[field] + '/\\';
  //   });
  //
  //   return indexField.includes(searchStr) ? [...acc, item] : [...acc];
  // }, []);

  return findArr.length > 0 ? findArr : [];
}

export function filterObjectNew<T>({ arr, searchStr }: IFilterObjectNew<T>) {
  return arr.filter((o: any) =>
    Object.entries(o).some((entry) => {
      return String(entry[1]).toLowerCase().includes(searchStr.toLowerCase());
    }),
  );
}
