import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import fileDownload from 'js-file-download';

interface IDownloadBigText {
  text: string;
  btnText?: string;
}

export default function DownloadBigText({ text, btnText = 'Download JSON' }: IDownloadBigText) {
  const makeTextFile = (text: string) => {
    const data = new Blob([text], { type: 'application/text' });
    fileDownload(new Blob([data]), `data_${new Date().getTime()}.log`);
    //saveAs(new Blob([data]), `text_${new Date().getTime()}.log`);
  };

  return (
    <Box style={{ width: '100%', textAlign: 'center' }}>
      <Button
        style={{ margin: '.2rem 0' }}
        variant="outlined"
        color="primary"
        onClick={() => {
          makeTextFile(text);
        }}
      >
        {btnText}
      </Button>
    </Box>
  );
}
