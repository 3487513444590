import SaveIcon from '@mui/icons-material/Save';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ISaveText {
  handlerDownload: () => void;
  handlerCopyClipboard?: () => void;
  text?: string;
  isPutText?: boolean;
  copyText?: string;
}

const CellContainer = styled(Box)(() => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const IconSave = styled(SaveIcon)((props) => ({
  color: props.theme.palette.primary.main,
  '&:hover': {
    color: props.theme.palette.primary.dark,
  },
}));

const IconClipboard = styled(CopyAllIcon)((props) => ({
  color: props.theme.palette.primary.main,
  '&:hover': {
    color: props.theme.palette.primary.dark,
  },
}));

export default function SaveFile({
  isPutText,
  text,
  handlerDownload,
  handlerCopyClipboard,
  copyText,
}: ISaveText) {
  const downloadFile = () => {
    handlerDownload();
  };

  const copyClipboardText = () => {
    handlerCopyClipboard && handlerCopyClipboard();
  };

  if (isPutText) {
    return (
      <CellContainer>
        <Tooltip placement={'top'} title={`Копіювати в буфер інфо ключа`}>
          <IconClipboard onClick={copyClipboardText} />
        </Tooltip>

        <Typography style={{ fontSize: 'inherit' }}>{text}</Typography>

        <Tooltip placement={'top'} title={`Зберегти файл ключа`}>
          <IconSave onClick={downloadFile} />
        </Tooltip>
      </CellContainer>
    );
  }
  return (
    <Box textAlign={'center'}>
      <IconSave onClick={downloadFile} />
      <IconClipboard onClick={downloadFile} />
    </Box>
  );
}
