import { IColumn } from '../../components/UI/CustomTable/components';

import { AlertType } from '../../context/snackbar-context';
import { CSO } from './cso.interface';

interface IGetColumns {
  lastConnectRRO: CSO[];

  showMessage(type: AlertType, message: string): void;

  setAuthenticated: (authenticated: boolean) => void;
}

export const getColumns = ({
  lastConnectRRO,
  showMessage,
  setAuthenticated,
}: IGetColumns): IColumn[] => {
  return [
    {
      field: 'terminalId',
      label: `Терминал`,
      hint: `Ідентифікатор термінала`,
      formatHeader: 'default',
      cell: { format: 'terminalId' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'address',
      label: `Адреса`,
      hint: `Адреса термінала`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 400,
    },
    {
      field: 'lastConnectDate',
      label: `Останнє з'єднання`,
      hint: `Дата останнього з'єднання`,
      formatHeader: 'default',
      cell: { format: 'last-date-time-connect' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'diffLastConnectDate',
      label: `Час до блокування`,
      hint: `Час до блокування`,
      formatHeader: 'default',
      cell: { format: 'diff-last-date-time-connect-from-current' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'ownerName',
      label: `Власник`,
      hint: `Власник РРО`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
    {
      field: 'rroModel',
      label: `Модель РРО`,
      hint: `Модель РРО`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'devId',
      label: `ID DEV`,
      hint: `ID DEV`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'datePersonalized',
      label: `Дата персоналізації`,
      hint: `Дата персоналізації`,
      formatHeader: 'default',
      cell: { format: 'date-time' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
    {
      field: 'fiscalNumber',
      label: `Фіскальний номер`,
      hint: `Фіскальний номер`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 100,
    },
  ];
};
