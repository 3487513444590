import { IColumn, IRow } from '../../components/UI/CustomTable/components';
import { Key } from './mpos-keys.interface';
import { getAsyncData } from '../../services/axios.service';
import FileDownload from 'js-file-download';
import moment from 'moment';
import { getKeyInfo } from '../../services/mpos-keys.service';
import { AlertType } from '../../context/snackbar-context';

interface IGetColumns {
  keys: Key[];
  showMessage(type: AlertType, message: string): void;

  setAuthenticated: (authenticated: boolean) => void;
}

export const getColumns = ({
  keys,
  showMessage,
  setAuthenticated,
}: IGetColumns): IColumn[] => {
  const handleDownload = async (row: IRow) => {
    const url = `/key/${row.KEY_ID}/file`;
    await getAsyncData({
      url,
      type: 'download',
    }).then((file) => {
      FileDownload(
        file,
        `mposdrv_${row.POSNUMBER}_${moment(row.DAT_EXPIRE).format(
          'YYYY-MM-DD',
        )}.key`,
      );
    });
  };

  const handleCopyClipboard = async (row: IRow) => {
    if (row) {
      let firm = await getKeyInfo(row.KEY_ID, showMessage, setAuthenticated);
      const templateCopyToClipboard =
        `Дата створення: ${moment(row.DAT).format('DD.MM.YYYY')}\n` +
        `Фірма: "${row.FIRM_NAME}"\n` +
        `Номер каси: ${row.POSNUMBER}\n` +
        `Дата закінчення: ${moment(row.DAT_EXPIRE).format('DD.MM.YYYY')}\n` +
        `${row.REMARK !== '' ? `Примітка: ${row.REMARK}\n` : ''}` +
        `Опції:\n${firm.join('\n')}`;
      navigator.clipboard.writeText(templateCopyToClipboard).then((res) => {
        showMessage('success', `Інформація по касі в буфері обміну`);
      });
    }
  };

  return [
    {
      field: 'DAT',
      label: `Дата створення`,
      hint: `Дата створення`,
      formatHeader: 'default',
      cell: { format: 'date-time' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
    {
      field: 'FIRM_NAME',
      label: `Фірма`,
      hint: `Фірма`,
      formatHeader: 'default',
      cell: { format: 'hrefLinkFirm' },
      align: 'center',
      alignData: 'center',
    },
    {
      field: 'POSNUMBER',
      label: `Номер каси`,
      hint: `номер каси`,
      formatHeader: 'default',
      cell: {
        format: 'saveFileAndCopyToClipboardInfo',
        isPutText: true,
        handleDownload: handleDownload,
        handleCopyClipboard: handleCopyClipboard,
      },
      align: 'center',
      alignData: 'center',
      width: 150,
      sortable: true,
    },
    {
      field: 'DAT_EXPIRE',
      label: `Дата закінчення`,
      hint: `Дата закінчення`,
      formatHeader: 'default',
      cell: { format: 'date-time', formatDate: 'DD.MM.YYYY' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
    {
      field: 'REMARK',
      label: `Примітка`,
      hint: `Примітка`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 350,
    },
  ];
};
