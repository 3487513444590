import { IColumn } from '../../components/UI/CustomTable/components';

export const getColumns = (): IColumn[] => {
  return [
    {
      field: 'NAME',
      label: `Ім'я користувача`,
      hint: `Ім'я користувача`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
    {
      field: 'LOGIN',
      label: `Логін`,
      hint: `Логін`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
    {
      field: 'LIST_FIRMS',
      label: `Фірми користувача`,
      hint: `Фірми користувача`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 550,
    },
    {
      field: 'IS_ADMIN',
      label: `Адміністратор`,
      hint: `Адміністратор`,
      formatHeader: 'default',
      cell: { format: 'boolean' },
      align: 'center',
      alignData: 'center',
      width: 150,
    },
  ];
};
