import { IColumn } from '../../components/UI/CustomTable/components';

export const getColumns = (): IColumn[] => {
  return [
    {
      field: 'TERMINAL_ID',
      label: `Терминал`,
      hint: `Ідентифікатор термінала`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 180,
    },
    {
      field: 'ADDRESS',
      label: `Адреса`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
    },
    {
      field: 'ZN',
      label: `Заводський номер`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 140,
    },
    {
      field: 'FN',
      label: `Фіскальний номер`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 140,
    },
    {
      field: 'DEV_ID',
      label: `ID DEV`,
      hint: `ID DEV`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 75,
    },
    {
      field: 'OWNER',
      label: `Власник`,
      hint: `Власник РРО`,
      formatHeader: 'default',
      cell: { format: 'default' },
      align: 'center',
      alignData: 'center',
      width: 350,
    },
  ];
};
