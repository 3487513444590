import * as React from 'react';
import { lazy, ReactElement, Suspense, useContext, useState } from 'react';
import { AppBar, Box, Typography, Toolbar } from '@mui/material';

import Loading from '../UI/Loading/Loading';

import { logout } from '../../services/auth.service';
import AuthContext from '../../context/main-context';
import SnackbarContext from '../../context/snackbar-context';

const Modal = lazy(() => import('../UI/Modal/Modal'));

interface ITopBar {
  children: ReactElement<any, any>;
}

export default function TopBar({ children }: ITopBar) {
  const [isOpenModal, setOpenModal] = useState(false);
  const { setAuthenticated } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  const handleLogout = async () => {
    logout(showMessage, setAuthenticated);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav">
          <Toolbar>{children}</Toolbar>
        </AppBar>
      </Box>

      <Suspense fallback={<Loading type={'fullScreen'} />}>
        {isOpenModal && (
          <Modal
            handleBtnOk={handleLogout}
            isOpen={isOpenModal}
            handleBtnCancel={() => setOpenModal(false)}
          >
            <Box sx={{ width: '300px' }}>
              <Typography>{'Ви дійсно бажаєте вийти?'}</Typography>
            </Box>
          </Modal>
        )}
      </Suspense>
    </>
  );
}
