import MainLayout from '../../components/Layout/MainLayout';
import InnerContainer from '../../components/Common/InnerContainer/InnerContainer';
import { Button, Grid } from '@mui/material';
import InputSearchLine from '../../components/UI/Input/InputSearchLine';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getLastConnectDate } from '../../services/cso.service';
import { Filter } from './cso.interface';
import AuthContext, { Page } from '../../context/main-context';
import SnackbarContext from '../../context/snackbar-context';
import { filterObject, getDataFromLocalStorage } from '../../helpers/methods';
import CustomTable from '../../components/UI/CustomTable';
import { getColumns } from './cso.column';
import InputFilled from '../../components/UI/Input/InputFilled';
import SettingsIcon from '@mui/icons-material/Settings';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const initialFilter: Filter = {
  searchStr: '',
  numberHoursBeforeRROLock: 48,
};

export default function CSO() {
  const { setAuthenticated, setCurrentPage } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  const [isLoading, setLoading] = useState(false);
  const [filter, setFilter] = useState<Filter>(initialFilter);
  const [isAdmin, setAdmin] = useState<boolean>(false);

  useEffect(() => {
    getLastConnectionRROFromServer().then();
    setAdmin(getDataFromLocalStorage('param') === 'T');
  }, []);

  const [lastConnectRRO, setLastConnectRRO] = useState([]);

  const handleChangeSearchStr = (searchStr: string) => {
    setFilter((prev) => ({ ...prev, searchStr }));
  };

  const handleChangeHoursBeforeRROLock = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFilter((prev) => ({
      ...prev,
      numberHoursBeforeRROLock: parseInt(e.target.value, 10),
    }));
  };

  const handleRefreshPage = async () => {
    await getLastConnectionRROFromServer();
  };

  const getLastConnectionRROFromServer = useCallback(async () => {
    try {
      setLoading(true);

      const rro = await getLastConnectDate(
        filter,
        showMessage,
        setAuthenticated,
      );
      setLastConnectRRO(rro);
    } finally {
      setLoading(false);
    }
  }, [filter, setAuthenticated, showMessage]);

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let filteredArr = filterObject({
      arr: lastConnectRRO || [],
      fields: ['ownerName', 'fiscalNumber'],
      searchStr: filter.searchStr,
    });
    filteredArr = filteredArr.length > 0 ? filteredArr.slice(0, 100) : [];
    /* Применение фильтра */
    return { arr: filteredArr, count: 0 };
  }, [lastConnectRRO, filter.searchStr]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns({ lastConnectRRO, showMessage, setAuthenticated });
  }, [lastConnectRRO, setAuthenticated, showMessage]);

  const handleApplyFilter = async () => {
    await getLastConnectionRROFromServer();
  };

  return (
    <MainLayout>
      <InnerContainer direction={'left'} show={true}>
        <Grid
          container
          spacing={3}
          sx={{
            paddingTop: '2rem',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Grid item xs={12} sm={12} md={3}>
            <InputSearchLine
              placeholder={`Пошуковий рядок`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeSearchStr(e.currentTarget.value);
              }}
              value={filter.searchStr}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputFilled
              label={'мінімальна кіл-ть годин до блокування'}
              type={'number'}
              value={filter.numberHoursBeforeRROLock}
              onChange={handleChangeHoursBeforeRROLock}
            />
          </Grid>
          <Grid
            xs={12}
            md={2}
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Button
              sx={{ height: '55px' }}
              fullWidth
              onClick={handleApplyFilter}
              variant={'outlined'}
              startIcon={<FilterAltIcon />}
            >
              Сформувати
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            {isAdmin && (
              <Button
                sx={{ height: '55px' }}
                fullWidth
                onClick={() => setCurrentPage(Page.RRO)}
                variant={'outlined'}
                startIcon={<SettingsIcon />}
              >
                Всі каси
              </Button>
            )}
          </Grid>
        </Grid>
        <CustomTable
          actionButton={{
            refreshIcon: true,
            refreshHint: 'Оновити',
            refreshBtnClick: handleRefreshPage,
          }}
          sx={{
            '& .MuiTableCell-root': {
              fontSize: '.75rem',
            },
            '& .MuiTableBody-root .MuiTableCell-root': {
              borderLeft: '1px solid #ccc',
            },
            '& .MuiTableBody-root .MuiTableCell-root:nth-last-of-type(-n+1)': {
              borderRight: '1px solid #ccc',
            },
          }}
          isSimple
          title={`Затримки з'єднання с ЦСО`}
          rows={rows.arr}
          columns={columns}
          isLoading={isLoading}
          count={rows.count}
          labelEmptyRows={`Рядки відсутні`}
        />
      </InnerContainer>
    </MainLayout>
  );
}
