import { makeStyles } from "@mui/styles";

export const useCustomStyles = makeStyles( ( theme: any ) => {

	const fontFamilyDefault = theme?.font?.fontFamilyDefault || 'inherit';
	const fontFamilyLabelBold = theme?.font?.fontFamilyLabelBold || 'inherit';

	return {
		titleDefault: {
			fontFamily: fontFamilyDefault,
			color: "#000",
			fontWeight: "600",
			fontSize: "16px",
			lineHeight: "21px",
			letterSpacing: "0.01em"
		},

		textDefault: {
			fontFamily: fontFamilyDefault,
			color: "#777",
			fontWeight: "500",
			fontSize: "16px",
			lineHeight: "21px",
			letterSpacing: "0.01em"
		},


		titleBold: {
			fontFamily: fontFamilyLabelBold,
			color: "#000",
			fontWeight: "700",
			fontSize: "22px",
			lineHeight: "27px"
		},

		fontDefault: {
			fontFamily: fontFamilyDefault,
		}

	}
} );
