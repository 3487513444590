import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TextFieldProps, BaseTextFieldProps } from '@mui/material/TextField';
import { Box } from '@mui/material';
import InputFilled from './InputFilled';

type IInputFilled = BaseTextFieldProps &
  TextFieldProps & { sx?: React.CSSProperties } & { placeholder: string | any };

const TextFieldSearch = styled(InputFilled)(() => ({
  backgroundColor: '#EDEDED',
  width: '340px',
}));

export default function InputSearchLine({
  placeholder = '',
  onChange,
  ...other
}: IInputFilled) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': { height: '45px' },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
      }}
    >
      <TextFieldSearch
        type={'text'}
        onChange={onChange}
        label={placeholder}
        placeholder={placeholder}
        style={{ borderRadius: '5px' }}
        {...other}
      />
    </Box>
  );
}
