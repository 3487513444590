import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactNode, useContext, useState } from 'react';
import { ICredentials } from './signin.interface';
import { Box, CardMedia, Typography } from '@mui/material';
import {
  Container,
  CredentialInput,
  GlobalContainer,
  ItemView,
  SubmitBtn,
  WrapperCardMedia,
} from './signin.style';
import AuthContext, { Page } from '../../context/main-context';
import { signIn } from '../../services/auth.service';
import SnackbarContext from '../../context/snackbar-context';

const defaultInputValues: ICredentials = {
  login: '',
  password: '',
};

export default function SignIn() {
  const [values, setValues] = useState<ICredentials>(defaultInputValues);
  const [isLoading, setLoading] = useState(false);

  const { setAuthenticated, setCurrentPage } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  let validationSchema = yup.object().shape({
    login: yup.string().required(`Логін не може бути пустим`),
    password: yup.string().required(`Пароль не може бути пустим`),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (data: any) => {
    data = data as ICredentials;
    setLoading(true);
    try {
      const res = await signIn(data, showMessage, setAuthenticated);
      res && setCurrentPage(Page.MAIN);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = { [e.currentTarget.name]: e.currentTarget.value };
    setValues((prev) => ({ ...prev, ...newValue }));
  };

  return (
    <GlobalContainer>
      <WrapperCardMedia image="/img/img_login.jpg" />

      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <ItemView style={{ borderRight: '1px solid #ccc' }}>
              <CardMedia
                component="img"
                image="/img/logo.png"
                alt="logoImage"
              />
            </ItemView>
            <ItemView>
              <Box style={{ textAlign: 'center', padding: '2rem' }}>
                <Typography
                  style={{ marginBottom: '.5rem' }}
                >{`Для подальшого користування послугами введіть облікові дані`}</Typography>

                <CredentialInput
                  label={`Логін`}
                  required
                  inputProps={register('login')}
                  onChange={handleChange}
                  value={values.login}
                  error={!!errors.login}
                  helperText={
                    errors.login ? (errors?.login?.message as ReactNode) : ' '
                  }
                />
                <CredentialInput
                  label={`Пароль`}
                  required
                  type={'password'}
                  inputProps={register('password')}
                  onChange={handleChange}
                  value={values.password}
                  error={!!errors.password}
                  helperText={
                    errors.password
                      ? (errors?.password?.message as ReactNode)
                      : ' '
                  }
                />

                <SubmitBtn
                  type={'submit'}
                  variant="contained"
                  loading={isLoading}
                >
                  {'Авторизація'}
                </SubmitBtn>
              </Box>
            </ItemView>
          </Container>
        </form>
      </Box>
    </GlobalContainer>
  );
}
