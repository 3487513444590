import { getAsyncData } from './axios.service';
import { AlertType } from '../context/snackbar-context';
import { Filter, Firm } from '../module/MposKeys/mpos-keys.interface';
import moment from 'moment';
import { setDataToLocalStorage } from '../helpers/methods';

export const getKeys = async (
  filter: Filter,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  let res;
  let req = {
    ...filter,
    DATE_CREATE: moment(filter.DATE_CREATE).format('YYYY-MM-DD'),
  };

  try {
    res = await getAsyncData({
      url: `/keys`,
      type: 'get',
      req,
    });

    if (res.error.errorCode === 200) {
      return res.data;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getFirms = async (
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  let res;
  try {
    res = await getAsyncData({
      url: `/firms`,
      type: 'get',
    });

    if (res.error.errorCode === 200) {
      const isAllFirm: boolean =
        res.data.findIndex(
          (firm: { FIRM_ID: number }) => firm.FIRM_ID === 0,
        ) !== -1;
      setDataToLocalStorage('firm', isAllFirm ? 0 : -1);
      return [
        ...res.data.map((firm: Firm) => ({
          ...firm,
          id: firm.FIRM_ID,
        })),
      ];
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getKeyInfo = async (
  keyId: number,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
) => {
  const url = `/key/${keyId}/info`;
  let res;
  try {
    res = await getAsyncData({
      url,
      type: 'get',
    });

    if (res.error.errorCode === 200) {
      return res.data.map((firm: string) => firm);
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
    }
  } catch (e) {
    console.log(e);
  }
};
