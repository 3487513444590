import AuthContext from '../../context/main-context';
import SnackbarContext from '../../context/snackbar-context';
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  geSyncRROFromCSO,
  getLocalRROFromServer,
} from '../../services/rro.service';
import { filterObjectNew } from '../../helpers/methods';
import { getColumns } from './rro.column';
import InnerContainer from '../../components/Common/InnerContainer/InnerContainer';
import MainLayout from '../../components/Layout/MainLayout';
import { Button, Grid } from '@mui/material';
import InputSearchLine from '../../components/UI/Input/InputSearchLine';
import CustomTable from '../../components/UI/CustomTable';
import { IRow } from '../../components/UI/CustomTable/components';
import Loading from '../../components/UI/Loading/Loading';
import { useSnackbar } from 'notistack';
import SyncIcon from '@mui/icons-material/Sync';

const EditRRO = lazy(() => import('./EditRRO'));

export default function RRO() {
  const { setAuthenticated, page } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);
  const [searchStr, setSearchStr] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [localRRO, setLocalRRO] = useState([]);
  const [row, setRow] = useState<IRow>();
  const [isOpenEditFormModal, setOpenEditFormModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getLocalRRO().then();
  }, []);

  const handleChangeSearchStr = (searchStr: string) => {
    setSearchStr(searchStr);
  };

  const getLocalRRO = async () => {
    try {
      setLoading(true);
      const rro = await getLocalRROFromServer(showMessage, setAuthenticated);
      setLocalRRO(rro);
    } finally {
      setLoading(false);
    }
  };

  /* Фильтр по столбцам таблицы  */
  const rows = useMemo(() => {
    let filteredArr = filterObjectNew({ arr: localRRO || [], searchStr });

    filteredArr = filteredArr.length > 0 ? filteredArr.slice(0, 100) : [];
    /* Применение фильтра */
    return { arr: filteredArr, count: 0 };
  }, [localRRO, searchStr]);

  /* Заполняем табличные заголовки */
  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const handleShowAddForm = () => {
    setRow({ id: -1 });
    setOpenEditFormModal(true);
  };

  const handleShowEditForm = (row: IRow) => {
    if (row) {
      setRow(row);
      setOpenEditFormModal(true);
    } else {
      enqueueSnackbar('Не обрана строка для редагування', { variant: 'error' });
    }
  };

  const handleDoubleClick = (row: IRow) => {
    handleShowEditForm(row);
  };

  const handleSyncRRO = async () => {
    try {
      setLoading(true);
      await geSyncRROFromCSO(showMessage, setAuthenticated).then(() =>
        getLocalRRO(),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      <InnerContainer direction={'left'} show={true}>
        <Grid
          container
          spacing={3}
          sx={{
            paddingTop: '2rem',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Grid item xs={12} sm={12} md={3}>
            <InputSearchLine
              placeholder={`Пошуковий рядок`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeSearchStr(e.currentTarget.value);
              }}
              value={searchStr}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Button
              sx={{ height: '55px' }}
              fullWidth
              onClick={handleSyncRRO}
              variant={'outlined'}
              startIcon={<SyncIcon />}
            >
              Синхронізація станцій з CSO
            </Button>
          </Grid>
        </Grid>
        <CustomTable
          actionButton={{
            // addIcon: true,
            // addHint: 'Додати РРО',
            // addBtnClick: handleShowAddForm.bind(null),
            //
            // editIcon: true,
            // editHint: 'Редагувати обрану РРО',
            // editBtnClick: handleShowEditForm.bind(null),

            refreshIcon: true,
            refreshHint: 'Оновити',
            refreshBtnClick: getLocalRRO,
          }}
          sx={{
            '& .MuiTableCell-root': {
              fontSize: '.75rem',
            },
            '& .MuiTableBody-root .MuiTableCell-root': {
              borderLeft: '1px solid #ccc',
            },
            '& .MuiTableBody-root .MuiTableCell-root:nth-last-of-type(-n+1)': {
              borderRight: '1px solid #ccc',
            },
          }}
          isSimple
          title={`Локальна БД РРО`}
          rows={rows.arr}
          columns={columns}
          isLoading={isLoading}
          count={rows.count}
          labelEmptyRows={`Рядки відсутні`}
          onClick={setRow.bind(null)}
          onDoubleClick={handleDoubleClick}
        />

        {
          <Suspense fallback={<Loading type={'fullScreen'} />}>
            {isOpenEditFormModal && (
              <EditRRO
                isOpen={isOpenEditFormModal}
                row={row}
                refreshData={getLocalRRO}
                onClose={() => setOpenEditFormModal(false)}
              />
            )}
          </Suspense>
        }
      </InnerContainer>
    </MainLayout>
  );
}
