import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { CSSProperties, useEffect, useState } from 'react';

export type IModalType = 'error' | 'default' | 'withoutBtn';

export interface IModalMessage {
  isOpen: boolean;
  isSubmitBtnLoading?: boolean;
  type?: IModalType;
  children?: any;
  btnOkTitle?: string;
  btnCancelTitle?: string;
  disabled?: boolean;
  style?: CSSProperties;

  test?(): void;

  handleBtnOk?(): void;

  handleValidForm?(): void;

  handleBtnCancel?(state: boolean): void;
}

const CustomDialog = styled(Dialog)((props) => ({
  fontFamily: props.theme.typography.fontFamily || 'inherit',
  color: '#000',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '21px',
  letterSpacing: '0.01em',
  '& .MuiPaper-root': {
    maxWidth: 'initial',
    borderRadius: '7px',
  },
}));

const CustomDialogContent = styled(DialogContent)(() => ({
  overflowY: 'auto',
  flex: '1 1 auto',
  whiteSpace: 'pre-wrap',
}));

export default function Modal({
  isOpen = false,
  isSubmitBtnLoading = false,
  type = 'default',
  children = <></>,
  btnOkTitle = 'Так',
  btnCancelTitle = 'Відміна',
  handleBtnOk,
  disabled = false,
  handleBtnCancel,
  handleValidForm,
  style,
  test,
}: IModalMessage) {
  const [isBtnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setBtnLoading(isSubmitBtnLoading);
  }, [isSubmitBtnLoading]);

  const handleClose = () => {
    handleBtnCancel && handleBtnCancel(false);
  };

  const handleSubmit = () => {
    handleBtnOk && handleBtnOk();
  };

  return (
    <CustomDialog open={isOpen} onClose={() => handleClose()} scroll={'paper'}>
      <form onSubmit={handleValidForm}>
        <CustomDialogContent style={{ ...style }}>
          {children}
        </CustomDialogContent>

        {type === 'withoutBtn' ? (
          <></>
        ) : (
          <DialogActions>
            {test && <Button onClick={test}>LOG</Button>}
            {handleValidForm ? (
              <LoadingButton
                variant={'contained'}
                color={'secondary'}
                disabled={disabled}
                type={'submit'}
                loading={isBtnLoading}
              >
                {btnOkTitle}
              </LoadingButton>
            ) : (
              <Button
                variant={'contained'}
                color={'secondary'}
                disabled={disabled}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {btnOkTitle}
              </Button>
            )}

            {['error'].indexOf(type) === -1 && (
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => {
                  handleClose();
                }}
              >
                {btnCancelTitle}
              </Button>
            )}
          </DialogActions>
        )}
      </form>
    </CustomDialog>
  );
}
