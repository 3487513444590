import { Autocomplete, Typography } from '@mui/material';
import { TextFieldFilled } from '../../../style/global';
import React, { useEffect, useState } from 'react';
import Loading from '../Loading/Loading';

interface IData {
  id: number;
  label: string;
}

interface IProps {
  data: any;
  fieldLabelOption: string;
  fnOnChange: Function;
  label: string;
  defaultValue?: number | string;
  disabled?: boolean;
  textStyle: React.CSSProperties;
  callbackType?: 'fullData' | 'onlyId';

  [key: string]: any;
}

interface IOptions {
  id: number;
  label: string;
}

export default function ComboBox({
  data,
  fieldLabelOption,
  fnOnChange,
  label,
  disabled = false,
  defaultValue = 0,
  textStyle = {},
  callbackType = 'onlyId',
  ...other
}: IProps) {
  const [val, setVal] = useState<any>();
  const [options, setOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    const newOptions: IData[] = data.map((item: any) => ({
      id: item.id,
      label: item[fieldLabelOption],
    }));

    const defaultOption: IData = newOptions.filter(
      (o) => o.id === (defaultValue === 0 ? newOptions[0].id : defaultValue),
    )[0];

    setVal(defaultOption);
    setOptions(newOptions);
  }, [defaultValue, data]);

  if (!val) {
    return (
      <Typography
        textAlign={'center'}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Loading type={'medium'} />
      </Typography>
    );
  }

  return (
    <Autocomplete
      disableClearable={true}
      color={'primary'}
      options={options}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id && option.label === value.label;
      }}
      value={val}
      defaultValue={{ id: 0, label: '' }}
      disabled={disabled}
      sx={{ width: '100%', '& .MuiFilledInput-root': { ...textStyle } }}
      onChange={(event: any, value: any): void => {
        if (value.id >= 0) {
          setVal(value);
          callbackType === 'onlyId' && fnOnChange(value.id);
          callbackType === 'fullData' && fnOnChange(value);
        }
      }}
      renderInput={(params) => (
        <TextFieldFilled
          {...params}
          sx={{
            ...textStyle,
            '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': {
              display: 'none',
            },
          }}
          variant="filled"
          label={label.toUpperCase()}
        />
      )}
      {...other}
    />
  );
}
