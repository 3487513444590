import { createContext } from 'react';

export enum Page {
  LOGIN = 'LOGIN',
  MAIN = 'MAIN',
  ADMIN = 'ADMIN',
  CSO = 'CSO',
  RRO = 'RRO',
}

export interface GlobalContext {
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  page: Page;
  setCurrentPage: (page: Page) => void;
}

export const AuthContext = createContext<GlobalContext>({
  authenticated: false,
  setAuthenticated: () => {},
  page: Page.LOGIN,
  setCurrentPage: () => {},
});

export default AuthContext;
