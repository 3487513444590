import { AlertType } from '../context/snackbar-context';
import { getAsyncData } from './axios.service';
import { User } from '../module/Users/user.interface';
import { ISetUser } from '../module/Users/UserEditor';

export const getUsers = async (
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
): Promise<User[]> => {
  return await getAsyncData({
    url: `/admin/users`,
    type: 'get',
  })
    .then((res) => {
      if (res.error.errorCode === 200) {
        return res.data.map((user: User) => ({
          ...user,
          id: user.USER_ID,
          IS_ADMIN: user.IS_ADMIN === 'T',
          LIST_FIRMS: user.FIRMS.map((firm) => firm.FIRM_NAME).join('; '),
        }));
      } else {
        showMessage('error', res.error.errorDescription);
        if (res.error.errorCode === 401) setAuthenticated(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editUser = async (
  id: number,
  req: ISetUser,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
): Promise<boolean> => {
  return await getAsyncData({
    url: `/admin/user/${id}/edit`,
    type: 'post',
    req,
  }).then((res) => {
    if (res.error.errorCode === 201) {
      showMessage('success', `Вдале виконання запиту!`);
      return true;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
      return false;
    }
  });
};

export const editUserPassword = async (
  id: number,
  password: string,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
): Promise<boolean> => {
  return await getAsyncData({
    url: `/admin/user/${id}/password`,
    type: 'post',
    req: { password },
  }).then((res) => {
    if (res.error.errorCode === 201) {
      showMessage('success', `Пароль змінено!`);
      return true;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
      return false;
    }
  });
};

export const deleteUser = async (
  id: number,
  showMessage: (type: AlertType, message: string) => void,
  setAuthenticated: (authenticated: boolean) => void,
): Promise<boolean> => {
  return await getAsyncData({
    url: `/admin/user/${id}`,
    type: 'delete',
  }).then((res) => {
    if (res.error.errorCode === 200) {
      showMessage('success', `Вдале виконання запиту!`);
      return true;
    } else {
      showMessage('error', res.error.errorDescription);
      if (res.error.errorCode === 401) setAuthenticated(false);
      return false;
    }
  });
};
