import { useCallback, useState } from 'react';
import { Page } from '../context/main-context';

export const useAuthenticated = () => {
  const [authenticated, setAuth] = useState(false);
  const [page, setPage] = useState<Page>(Page.LOGIN);

  const setAuthenticated = useCallback((authenticated: boolean): void => {
    setAuth(authenticated);
  }, []);

  const setCurrentPage = useCallback((page: Page): void => {
    setPage(page);
  }, []);

  return {
    authenticated,
    setAuthenticated,
    page,
    setCurrentPage,
  };
};
