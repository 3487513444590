import { TablePagination } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

export interface ICustomPagination {
	/**
	 * Номер установленной страницы
	 */
	pageNum?: number;
	/**
	 * Кол-во всего строк в запросе
	 */
	count?: number;
	/**
	 *  Какие варианты отображения кол-ва строк существует : ( [10, 20, 30] )
	 */
	rowsPerPageOptions?: number[];
	/**
	 * Отображение по умолчанию 25 строк
	 */
	rowsPerPageDefault?: number;

	onChangeRowsPerPage?( value: number ): void;

	onChangePage?( value: number ): void;
}

export default function CustomPagination( {
	                                          pageNum,
	                                          count = 0,
	                                          rowsPerPageOptions,
	                                          onChangeRowsPerPage,
	                                          rowsPerPageDefault = 25,
	                                          onChangePage
                                          }: ICustomPagination ) {
	const [ page, setPage ] = useState( 0 );
	const [ rowsPerPage, setRowsPerPage ] = useState( 25 );

	useEffect( () => {

		rowsPerPageDefault && setRowsPerPage( rowsPerPageDefault );

	}, [ rowsPerPageDefault ] );

	useEffect( () => {
		pageNum && setPage( pageNum - 1 );
	}, [ pageNum ] )

	const handleChangePage = (
		event: any,
		newPage: number,
	) => {
		if ( onChangePage ) {
			onChangePage( newPage + 1 );
		}
		setPage( newPage );
	};

	const handleChangeRowsPerPage = (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {

		if ( onChangeRowsPerPage ) {
			onChangeRowsPerPage( parseInt( event.target.value ) );
		}

		setRowsPerPage( parseInt( event.target.value, 10 ) );
		setPage( 0 );
	};

	return <TablePagination
		component="div"
		count={ count }
		page={ page }
		rowsPerPageOptions={ rowsPerPageOptions }
		onPageChange={ handleChangePage }
		rowsPerPage={ rowsPerPage }
		onRowsPerPageChange={ handleChangeRowsPerPage }
		showFirstButton
		showLastButton
	/>
}
