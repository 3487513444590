import { Box, Grid, IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { IRow } from '../../';

interface IActionButton {
  action: Partial<IAction>;
  row: IRow | undefined;
}

export interface IAction {
  filterIcon: boolean;
  filterHint: string;

  filterBtnClick(): void;

  filterClearIcon: boolean;
  filterClearHint: string;

  filterClearBtnClick(): void;

  addIcon: boolean;
  addHint: string;

  addBtnClick(): void;

  editIcon: boolean;
  editHint: string;

  editBtnClick(row: IRow): void;

  delIcon: boolean;
  delHint: string;

  delBtnClick(row: IRow): void;

  refreshIcon: boolean;
  refreshHint: string;

  refreshBtnClick(): void;

  saveIcon: boolean;
  saveHint: string;

  saveBtnClick(): void;

  saveSecondIcon: boolean;
  saveSecondHint: string;

  saveSecondBtnClick(): void;
}

const WrapperBlockButton = styled(Grid)(() => ({
  marginLeft: '2rem',
}));

const TooltipHint = styled(Tooltip)(({ theme }) => ({
  color: '#ccc',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}));

export default function ActionButton({ action, row }: IActionButton) {
  const {
    filterIcon,
    filterHint,
    filterBtnClick,
    filterClearIcon,
    filterClearHint,
    filterClearBtnClick,
    addIcon,
    addHint,
    addBtnClick,
    editIcon,
    editHint,
    editBtnClick,
    delIcon,
    delHint,
    delBtnClick,
    refreshIcon,
    refreshHint,
    refreshBtnClick,
    saveIcon,
    saveHint,
    saveBtnClick,
    saveSecondIcon,
    saveSecondHint,
    saveSecondBtnClick,
  } = action;

  return (
    <>
      <WrapperBlockButton>
        {
          /* Добавить */
          addIcon && (
            <TooltipHint title={addHint} placement="top-end">
              <IconButton color={'secondary'} onClick={addBtnClick}>
                {' '}
                <AddIcon />{' '}
              </IconButton>
            </TooltipHint>
          )
        }

        {
          /* Редактировать */
          editIcon && (
            <TooltipHint title={editHint} placement="top-end">
              <IconButton
                color={'secondary'}
                onClick={editBtnClick?.bind(null, row as IRow)}
              >
                {' '}
                <ModeEditIcon />
              </IconButton>
            </TooltipHint>
          )
        }

        {
          /* Удалить */
          delIcon && (
            <TooltipHint title={delHint} placement="top-end">
              <IconButton
                color={'secondary'}
                onClick={delBtnClick?.bind(null, row as IRow)}
              >
                {' '}
                <DeleteIcon />
              </IconButton>
            </TooltipHint>
          )
        }

        {
          /* Обновить */
          refreshIcon && (
            <TooltipHint title={refreshHint} placement="top-end">
              <IconButton color={'secondary'} onClick={refreshBtnClick}>
                {' '}
                <RefreshIcon />{' '}
              </IconButton>
            </TooltipHint>
          )
        }
      </WrapperBlockButton>

      <WrapperBlockButton>
        {
          /* Фильтр */
          filterIcon && (
            <TooltipHint title={filterHint} placement="top-end">
              <IconButton color={'secondary'} onClick={filterBtnClick}>
                {' '}
                <FilterAltIcon />{' '}
              </IconButton>
            </TooltipHint>
          )
        }

        {
          /* Очистка фильтра */
          filterClearIcon && (
            <TooltipHint title={filterClearHint} placement="top-end">
              <IconButton color={'secondary'} onClick={filterClearBtnClick}>
                {' '}
                <FilterAltOffIcon />{' '}
              </IconButton>
            </TooltipHint>
          )
        }
      </WrapperBlockButton>

      <WrapperBlockButton>
        {
          /* Сохранить */
          saveIcon && (
            <TooltipHint title={saveHint} placement="top-end">
              <IconButton color={'secondary'} onClick={saveBtnClick}>
                {' '}
                <SaveIcon />{' '}
              </IconButton>
            </TooltipHint>
          )
        }
        {
          /* Сохранить доп. кнопка */
          saveSecondIcon && (
            <TooltipHint title={saveSecondHint} placement="top-end">
              <IconButton color={'secondary'} onClick={saveSecondBtnClick}>
                {' '}
                <SaveAltIcon />{' '}
              </IconButton>
            </TooltipHint>
          )
        }
      </WrapperBlockButton>
    </>
  );
}
