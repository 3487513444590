import { TFormatColumn } from "../../interface/interface.custom";
import { useMemo } from "react";


interface IFormatColumn {
	column: {
		formatHeader?: TFormatColumn;
		label: string;
	}
}

export default function FormatColumn ( { column }: IFormatColumn ) {

	const renderFormat = useMemo( () => {
		const { formatHeader, label } = column;

		let response: JSX.Element = <></>;

		switch ( formatHeader ) {
			case 'empty': {
				response = <></>;
				break;
			}

			case 'default': {
				response = <>{ label }</>
				break;
			}
		}

		return response;

	}, [ column ] );

	return renderFormat;
}