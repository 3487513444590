import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

interface ILinkOpacity {
	link: string;
	label: string;
}

const LinkLabel = styled( Typography )( (props) => ( {
	fontSize: "inherit", opacity: ".2", "&:hover": { opacity: "1", color:  props.theme.palette.secondary.main }
} ) );

const StyleNavLink = styled( NavLink )( () => ( {
	textDecoration: 'none',
	color: "inherit"
} ) );

export default function LinkOpacity ( { link, label }: ILinkOpacity ) {
	return <StyleNavLink to={ link }> <LinkLabel>{ label }</LinkLabel> </StyleNavLink>
}
