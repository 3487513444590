import { Box, Button, Typography } from '@mui/material';
import React, {
  ReactElement,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import AuthContext, { Page } from '../../context/main-context';
import SnackbarContext from '../../context/snackbar-context';
import { logout } from '../../services/auth.service';
import TopBar from '../Layout/TopBar';
import Loading from '../UI/Loading/Loading';
import Modal from '../UI/Modal/Modal';
import LogoutIcon from '@mui/icons-material/Logout';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HomeIcon from '@mui/icons-material/Home';
import { getDataFromLocalStorage } from '../../helpers/methods';
import Tooltip from '@mui/material/Tooltip';

export default function MainLayout({
  children,
}: {
  children: ReactElement<any, any>;
}) {
  const [isOpenModal, setOpenModal] = useState(false);
  const { setAuthenticated, setCurrentPage } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);

  const [param, setParam] = useState<{ isAdmin: boolean; isAllFirm: number }>({
    isAdmin: false,
    isAllFirm: -1,
  });

  useEffect(() => {
    setTimeout(() => {
      const isAdmin = getDataFromLocalStorage('param') === 'T';
      const isAllFirm = parseInt(getDataFromLocalStorage('firm') as string, 10);
      setParam({ isAdmin, isAllFirm });
    }, 2000);
  }, []);

  const handleConfirmLogout = async () => {
    setOpenModal(true);
  };

  const signOut = () => {
    logout(showMessage, setAuthenticated);
  };

  return (
    <>
      <TopBar>
        <>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              display: { sm: 'block' },
              fontSize: { sm: '.7rem', md: '1.5rem' },
            }}
          >{`MposKeys`}</Typography>
          <Box sx={{ display: { sm: 'block' } }}>
            {param.isAdmin ||
              (param.isAllFirm === 0 && (
                <>
                  <Button
                    sx={{ fontSize: { xs: '.55rem', md: '1rem' } }}
                    color={'warning'}
                    onClick={setCurrentPage.bind(null, Page.MAIN)}
                  >
                    <Tooltip placement={'bottom'} title={'Головна'}>
                      <HomeIcon />
                    </Tooltip>
                  </Button>
                </>
              ))}
            {param.isAllFirm === 0 && (
              <>
                <Button
                  sx={{ fontSize: { xs: '.55rem', md: '1rem' } }}
                  color={'warning'}
                  onClick={setCurrentPage.bind(null, Page.CSO)}
                >
                  <Tooltip
                    placement={'bottom'}
                    title={'Затримки передачі пакетів на еквайер'}
                  >
                    <MoveUpIcon />
                  </Tooltip>
                </Button>
              </>
            )}
            {param.isAdmin && (
              <>
                <Button
                  sx={{ fontSize: { xs: '.55rem', md: '1rem' } }}
                  color={'warning'}
                  onClick={setCurrentPage.bind(null, Page.ADMIN)}
                >
                  <Tooltip placement={'bottom'} title={'Адміністрування'}>
                    <EngineeringIcon />
                  </Tooltip>
                </Button>
              </>
            )}
            <Button
              sx={{ fontSize: { xs: '.55rem', md: '1rem' } }}
              color={'warning'}
              onClick={handleConfirmLogout}
            >
              <Tooltip placement={'bottom'} title={'Вихід'}>
                <LogoutIcon />
              </Tooltip>
            </Button>
          </Box>
        </>
      </TopBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 8 }}>
        {children}
      </Box>
      <Suspense fallback={<Loading type={'fullScreen'} />}>
        {isOpenModal && (
          <Modal
            handleBtnOk={signOut}
            isOpen={isOpenModal}
            handleBtnCancel={() => setOpenModal(false)}
          >
            <Box sx={{ width: '300px' }}>
              <Typography>{`Ви впевнені, що хочете вийти?`}</Typography>
            </Box>
          </Modal>
        )}
      </Suspense>
    </>
  );
}
